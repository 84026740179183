import React, { useState, useEffect, Fragment } from 'react';
import ReactMarkdown from 'react-markdown';
import {
    Link,
    useParams
} from 'react-router-dom';

const importAll = (r) => r.keys().map(r);
const markdownFiles = importAll(require.context('../data/alfredo_ferraresi', false, /\.md$/))
  .sort();

const About = props => {
    const [ markdown, setMarkdown ] = useState('markdown');
    const [ submenu, setSubmenu ] = useState('submenu');
    const { page } = useParams();

    useEffect(() => {
        async function fetchMd(currentPage) {
            setSubmenu(currentPage);
            const mapFile = [
                'introduccion_a_la_persona',
                'su_importancia_dentro_del_gremio_de_farmacia'
            ];

            const index = mapFile.findIndex(page => page === currentPage);
            if (index >= 0) {
                let response = await fetch(markdownFiles[index])
                    .then(res => res.text())
                    .catch(err => {
                        console.error(err);
                        return "# Página no encontrada ## Error 404";
                    });
                setMarkdown(response);
            } else {
                setMarkdown("# Página no encontrada\r## Error 404");
            }
        }

        if (page !== submenu) {
            fetchMd(page);
        }
    });

    const getActiveClass = slug => {
        return page === slug ? 'on' : '';
    };

    return (
        <Fragment>
            <aside className="grid-25">
                <nav role="navigation">
                    <ul>
                        <li>
                            <Link to="/alfredo_ferraresi/introduccion_a_la_persona" className={getActiveClass('introduccion_a_la_persona')}>
                                {'Introducción a la persona'}
                            </Link>
                        </li>
                        <li>
                            <Link to="/alfredo_ferraresi/su_importancia_dentro_del_gremio_de_farmacia" className={getActiveClass('su_importancia_dentro_del_gremio_de_farmacia')}>
                                {'Su importancia dentro del gremio de Farmacia'}
                            </Link>
                        </li>
                    </ul>
                </nav>
            </aside>
            
            <article className="grid-75">
                <ReactMarkdown source={markdown} />
            </article>
        </Fragment>
    );
}

export default About;
