import React from 'react';
import {
  Link,
  useLocation
} from "react-router-dom";
import { startsWith } from 'lodash';

const Header = () => {
    const location = useLocation();

    return (
        <header className="grid-container">
            <h1 id="logo" className="mobile-grid-30">
                <a href="/"><small>{'en memoria de'}</small>{'Alfredo Ferraresi'}</a>
            </h1>

            <nav id="main" className="mobile-grid-70" role="navigation">
                <ul>
                    <li>
                        <Link to="/alfredo_ferraresi/introduccion_a_la_persona" className={startsWith(location.pathname, '/alfredo_ferraresi') ? 'on' : ''}>{'Alfredo Ferraresi'}</Link>
                    </li>
                    <li>
                        <Link to="/su_trayectoria_sindical/el_inicio" className={startsWith(location.pathname, '/su_trayectoria_sindical') ? 'on' : ''}>{'Su Trayectoria Sindical'}</Link>
                    </li>
                    <li>
                        <Link to="/contacto" className={startsWith(location.pathname, '/contacto') ? 'on' : ''}>{'Contacto'}</Link>
                    </li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;