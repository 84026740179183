import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Axios from 'axios';

const Contact = () => {
    const [ message, setMessage ] = useState('');
    const [ sending, setSending ] = useState(false);
    const { register, handleSubmit, errors } = useForm();

    const onSubmit = data => {
        console.log(data);
        setSending(true);

        Axios.post('/send.php', { ...data })
            .then(res => {
                console.log(res);
                setMessage("Mensaje enviado");
            })
            .catch(err => {
                console.log(err);
                setMessage("ERROR. El mensaje NO pudo ser enviado");
            })
            .then(() => {
                setMessage("Mensaje enviado");
                setSending(false);
            });
    }

    return (
        <article className="grid-75 prefix-25">
            <div>{message}</div>
            <form onSubmit={handleSubmit(onSubmit)} method="post" id="form" name="form">
                <label htmlFor="name">Nombre <abbr title="obligatorio">*</abbr></label>
                {errors.name && errors.name.type === "required" && <span>El nombre es requerido</span>}
                <input
                    ref={register({ required: true })}
                    type="text"
                    id="name"
                    name="name"
                    aria-invalid={errors.name ? "true" : "false"}
                /><br />

                <label htmlFor="email">Email <abbr title="obligatorio">*</abbr></label>
                {errors.email && errors.email.type === "required" && <span>El email es requerido</span>}
                <input
                    ref={register({ required: true })}
                    type="email"
                    id="email"
                    name="email"
                    aria-invalid={errors.email ? "true" : "false"}
                /><br />

                <label htmlFor="phone">Teléfono</label>
                <input
                    ref={register}
                    type="text"
                    id="phone"
                    name="phone"
                /><br />
                
                <label htmlFor="message">Mensaje <abbr title="obligatorio">*</abbr></label>
                {errors.message && errors.message.type === "required" && <span>Mensaje requerido</span>}
                <textarea
                    ref={register({ required: true })}
                    id="message"
                    name="message"
                    aria-invalid={errors.message ? "true" : "false"}
                ></textarea><br />

                <button type="submit" disabled={sending}>Enviar</button>
            </form>
        </article>
    );
}

export default Contact;