import React from 'react';
import './styles/style.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import About from './pages/About';
import Contact from './pages/Contact';
import Path from './pages/Path';
import Header from './componets/Header';
import Banner from './componets/Banner';
import Footer from './componets/Footer';

function App() {
  return (
    <Router>

      <Header />

      <Banner />

      <section className="grid-container">
        <Switch>
          <Route path="/alfredo_ferraresi/:page" component={About} />
          <Route path="/su_trayectoria_sindical/:page" component={Path} />
          <Route path="/contacto" component={Contact} />
          <Route path="/">
            <Redirect to="/alfredo_ferraresi/introduccion_a_la_persona" />
          </Route>
        </Switch>
      </section>

      <Footer />
    </Router>
  );
}

export default App;
