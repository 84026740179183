import React from 'react';

export default () => (
    <footer>
        <div className="grid-container">
            <div className="grid-80">
                {'Copyright ADEF 2020 - '}
                <a href="http://www.adef.org.ar">{'http://www.adef.org.ar'}</a>
                {' - Rincón 1044 - '}<br />
                {'Ciudad Autónoma de Buenos Aires - C1227ACL - Tel: 4941-3897 / 8015 / 5386'}
            </div>
            <div className="grid-20 align-right">
                <a href="http://www.multimake.com" target="_target">{'multimake'}</a>
            </div>
        </div>
    </footer>
);