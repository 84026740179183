import React from 'react';

export default () => (
    <div className="banner">
        <div className="grid-container">
            <p className="grid-40 prefix-5">{'10/03/1932 - 2/03/2013'}</p>
        </div>
    </div>
);

