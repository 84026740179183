import React, { useState, useEffect, Fragment } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link, useParams } from 'react-router-dom';

const importAll = (r) => r.keys().map(r);
const markdownFiles = importAll(require.context('../data/su_trayectoria_sindical', false, /\.md$/))
  .sort();

const Path = props => {
    const [ markdown, setMarkdown ] = useState('markdown');
    const [ submenu, setSubmenu ] = useState('submenu');
    const { page } = useParams();

    useEffect(() => {
        async function fetchMd(currentPage) {
            setSubmenu(currentPage);
            const mapFile = [
				'anos_60',
				'anos_70',
				'camino_hacia_la_vuelta_de_la_democracia',
				'comienzo_de_una_nueva_era',
				'decada_menemista_y_el_neoliberalismo',
				'decadencia_del_neoliberalismo',
				'el_inicio',
				'final',
				'perdidas_lucha_resistencia',
				'primera_dictadura',
				'tiempos_de_juventud'
            ];
            
            const index = mapFile.findIndex(page => page === currentPage);
            if (index >= 0) {
                let response = await fetch(markdownFiles[index])
                    .then(res => res.text())
                    .catch(err => {
                        console.error(err);
                        return "# Página no encontrada ## Error 404";
                    });
                setMarkdown(response);
            } else {
                setMarkdown("# Página no encontrada\r## Error 404");
            }
        }

        if (page !== submenu) {
            fetchMd(page);
        }
    });

    const getActiveClass = slug => {
        return page === slug ? 'on' : '';
    };

    return (
        <Fragment>
            <aside className="grid-25">
                <nav role="navigation">
                    <ul>
                        <li>
                            <Link to="/su_trayectoria_sindical/el_inicio" className={getActiveClass('el_inicio')}>{'El Inicio'}</Link>
                        </li>
                        <li>
                            <Link to="/su_trayectoria_sindical/tiempos_de_juventud" className={getActiveClass('tiempos_de_juventud')}>{'Tiempos de juventud. La primavera de la militancia'}</Link>
                        </li>
                        <li>
                            <Link to="/su_trayectoria_sindical/primera_dictadura" className={getActiveClass('primera_dictadura')}>{'Primera Dictadura. La revolución libertadora'}</Link>
                        </li>
                        <li>
                            <Link to="/su_trayectoria_sindical/anos_60" className={getActiveClass('anos_60')}>{'Años 60'}</Link>
                        </li>
                        <li>
                            <Link to="/su_trayectoria_sindical/anos_70" className={getActiveClass('anos_70')}>{'Años 70'}</Link>
                        </li>
                        <li>
                            <Link to="/su_trayectoria_sindical/perdidas_lucha_resistencia" className={getActiveClass('perdidas_lucha_resistencia')}>{'Dictactura del 76. Perdidas, lucha, resistencia'}</Link>
                        </li>
                        <li>
                            <Link to="/su_trayectoria_sindical/camino_hacia_la_vuelta_de_la_democracia" className={getActiveClass('camino_hacia_la_vuelta_de_la_democracia')}>{'La década de los 80. Camino hacia la vuelta de la Democracia'}</Link>
                        </li>
                        <li>
                            <Link to="/su_trayectoria_sindical/decada_menemista_y_el_neoliberalismo" className={getActiveClass('decada_menemista_y_el_neoliberalismo')}>{'La decada Menemista y el Neoliberalismo'}</Link>
                        </li>
                        <li>
                            <Link to="/su_trayectoria_sindical/decadencia_del_neoliberalismo" className={getActiveClass('decadencia_del_neoliberalismo')}>{'Decadencia del Neoliberalismo. Gobierno de la Alianza'}</Link>
                        </li>
                        <li>
                            <Link to="/su_trayectoria_sindical/comienzo_de_una_nueva_era" className={getActiveClass('comienzo_de_una_nueva_era')}>{'Comienzo de una nueva era'}</Link>
                        </li>
                        <li>
                            <Link to="/su_trayectoria_sindical/final" className={getActiveClass('final')}>{'Final'}</Link>
                        </li>
                    </ul>
                </nav>
            </aside>
            
            <article className="grid-75">
                <ReactMarkdown source={markdown} />
            </article>
        </Fragment>
    );
}

export default Path;
